 @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playwrite+IS:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



 body {
   margin: 0;
   background-color: #ededed !important;
   font-family: "Poppins", sans-serif;
   font-weight: 100;
   font-style: normal;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 h1 {
   font-size: 48.83px !important;
   color: #D2B805 !important;
   margin-top: 45px;
   font-weight: bold;
 }

 .para {
   text-align: justify;
   color: gray;
   font-size: 16px;
   padding-left: 8px;
 }

 h3 {
   font-size: 34.06px !important;
   margin-top: 30px !important;
 }

 .li-points {
   padding-top: 10px;
   text-align: justify;
   font-size: 16px;
 }

 .content-pading {
   padding: 0px 20px !important;

 }

 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }

 @media screen and (min-width:320px) and (max-width:768px) {
   .content-pading {
     padding: 0px !important;
   }

   h1 {
     font-size: 38.83px !important;
     color: #D2B805 !important;
     margin-top: 45px;
     font-weight: bold;
   }

   h3 {
     font-size: 26.06px !important;
     margin-top: 30px !important;
   }
 }